import { LitElement, html, css } from 'lit';
import WarpElement from '@warp-ds/elements-core';

class NotificationBubble extends LitElement {
    static get styles() {
        return [
            ...WarpElement.styles,
            css`
                .bubble-colors {
                    background-color: #d91f0a;
                    color: white;
                }
                *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.border-current{border-color:currentColor}.top-12{top:1.2rem}.top-2{top:.2rem}.static{position:static}.h-12{height:1.2rem}.w-12{width:1.2rem}.-mr-2{margin-right:-.2rem}@media (min-width:480px){.sm\\:right-auto{right:auto}.sm\\:mr-16{margin-right:1.6rem}}@media (min-width:768px){.md\\:left-20{left:2rem}.md\\:static{position:static}.md\\:ml-4{margin-left:.4rem}.md\\:mr-0{margin-right:0}};
            `,
        ];
    }

    static get properties() {
        return {
            show: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.show = false;
    }

    render() {
        if (!this.show) return html``;
        return html`
            <span id="top-bar_notifications-count-bubble" class="absolute top-2 -mr-2 md:static md:mr-0"
                ><span
                    id="notifications-bubble"
                    class="bubble-colors absolute sm:right-auto sm:mr-16 md:left-20 md:ml-4 top-12 rounded-full w-12 h-12 border-2 border-current"
                ></span
            ></span>
        `;
    }
}

customElements.define('notification-bubble', NotificationBubble);
