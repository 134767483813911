window.addEventListener('load', () => {
    const topbar = document.querySelector('finn-topbar');

    topbar?.addEventListener('click-notifications', () => {
        const pulseClient = /** @type {import("@finn-no/pulse-sdk")} */ (
            // @ts-ignore
            window.finnPulseClient
        );
        if (pulseClient) {
            // JIRA: PF-129
            pulseClient.trackEvent({
                type: 'Click',
                intent: 'Open',
                name: 'Notifications icon clicked',
                object: {
                    id: 'header-notifications-link',
                    type: 'UIElement',
                    elementType: 'Global navigation icon',
                },
                vertical: {
                    name: 'common_pages',
                },
            });
        }
    });

    topbar?.addEventListener('new-ad', () => {
        // @ts-ignore
        if (window.finnAnalytics) {
            // @ts-ignore
            window.finnAnalytics.trackEventLater({
                analyticsName: 'new-ad.create-new-ad.global-menu',
                eventKpi: 'effect',
            });
        }

        const pulseClient = /** @type {import("@finn-no/pulse-sdk")} */ (
            // @ts-ignore
            window.finnPulseClient
        );
        if (pulseClient) {
            // JIRA: PF-129
            pulseClient.trackEvent({
                type: 'Click',
                intent: 'Open',
                name: 'New ad icon clicked',
                object: {
                    id: 'header-new-ad-link',
                    type: 'UIElement',
                    elementType: 'Global navigation icon',
                },
                vertical: {
                    name: 'common_pages',
                },
            });
        }
    });

    topbar?.addEventListener('click-messages', () => {
        const pulseClient = /** @type {import("@finn-no/pulse-sdk")} */ (
            // @ts-ignore
            window.finnPulseClient
        );
        if (pulseClient) {
            // JIRA: PF-129
            pulseClient.trackEvent({
                type: 'Click',
                intent: 'Open',
                name: 'Messages icon clicked',
                object: {
                    id: 'header-messages-link',
                    type: 'UIElement',
                    elementType: 'Global navigation icon',
                },
                vertical: {
                    name: 'common_pages',
                },
            });
        }
    });

    topbar?.addEventListener('login', () => {
        // @ts-ignore
        if (window.finnAnalytics) {
            // @ts-ignore
            window.finnAnalytics.trackEventLater({
                analyticsName: 'user-admin.log-in.intentional-login',
                eventKpi: 'action',
                stringValue: 'topbar',
                eventFlags: 'start',
            });
        }

        const pulseClient = /** @type {import("@finn-no/pulse-sdk")} */ (
            // @ts-ignore
            window.finnPulseClient
        );
        if (pulseClient) {
            pulseClient.trackEvent({
                type: 'Click',
                intent: 'Login',
                name: 'Log in',
                object: {
                    id: 'header-login-link',
                    type: 'UIElement',
                    elementType: 'Link',
                },
                target: {
                    id: '/auth/login',
                    type: 'Form',
                    name: 'Log in',
                },
            });
        }
    });

    topbar?.addEventListener('click-profile', () => {
        const pulseClient = /** @type {import("@finn-no/pulse-sdk")} */ (
            // @ts-ignore
            window.finnPulseClient
        );
        if (pulseClient) {
            // JIRA: PF-129
            pulseClient.trackEvent({
                type: 'Click',
                intent: 'Open',
                name: 'Profile icon clicked',
                object: {
                    id: 'header-profile-link',
                    type: 'UIElement',
                    elementType: 'Global navigation icon',
                },
                vertical: {
                    name: 'common_pages',
                },
            });
        }
    });
});
