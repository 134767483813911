import { html } from 'lit';

export default (description) =>
    html`<svg width="343" height="200" viewBox="0 0 343 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <desc>${description}</desc>
        <rect width="343" height="200" rx="16" fill="#F6F6F6" />
        <path
            d="M157.378 40.7737C154.427 27.4903 151.664 14.2504 149.396 0.865764C149.294 -0.00242855 150.516 -0.378645 150.748 0.504017C151.562 3.60057 152.318 6.69712 153.016 9.82262C153.5 12.006 153.994 14.1827 154.487 16.3593C155.61 21.3083 156.732 26.2565 157.742 31.2814C158.039 32.7952 158.34 34.3118 158.641 35.8299C159.016 37.7222 159.391 39.6169 159.762 41.5116C159.947 42.4018 160.125 43.3131 160.302 44.2136L160.302 44.2148C160.419 44.8069 160.534 45.3945 160.649 45.9684C160.989 47.2315 161.122 48.541 161.042 49.8463C160.955 50.483 160.053 50.4685 159.762 50.0199C159.11 48.6741 158.678 47.233 158.483 45.7513C158.324 45.0296 158.162 44.3051 158 43.579L158 43.5772C157.791 42.6444 157.582 41.709 157.378 40.7737Z"
            fill="url(#paint0_radial_14_5482)"
        />
        <path
            d="M169.84 35.2182V35.2173C169.895 30.5438 169.95 25.8402 170.202 21.1672C170.202 20.9753 170.278 20.7913 170.415 20.6556C170.551 20.5199 170.736 20.4437 170.929 20.4437C171.121 20.4437 171.306 20.5199 171.443 20.6556C171.579 20.7913 171.656 20.9753 171.656 21.1672C171.961 27.6063 172.063 34.1322 172.063 40.6292C172.079 42.4522 172.05 44.2752 172.022 46.1007L172.022 46.1017V46.1098C171.999 47.5704 171.976 49.0326 171.976 50.4977C171.874 52.1183 171.874 53.7534 171.874 55.374C172.028 56.8661 171.87 58.3738 171.408 59.8018C171.344 59.9034 171.255 59.9872 171.15 60.0453C171.044 60.1034 170.926 60.1338 170.805 60.1338C170.685 60.1338 170.566 60.1034 170.46 60.0453C170.355 59.9872 170.266 59.9034 170.202 59.8018C169.751 58.3717 169.602 56.8641 169.766 55.374V40.6292C169.798 38.8325 169.819 37.0272 169.84 35.2182Z"
            fill="url(#paint1_radial_14_5482)"
        />
        <path
            d="M145.186 44.0224C142.927 38.7207 140.669 33.4206 138.608 28.0399V28.0254C138.317 27.273 139.524 26.7955 139.873 27.4901C142.277 32.251 144.513 37.0902 146.753 41.9353C147.236 42.9803 147.719 44.0262 148.204 45.0709C149.571 48.0662 150.864 51.0904 152.158 54.1291C152.46 54.8316 152.762 55.5502 153.062 56.2651C153.398 57.067 153.733 57.8643 154.063 58.6292C154.833 59.9345 155.327 61.3819 155.517 62.8834C155.511 63.0181 155.467 63.1483 155.389 63.2587C155.311 63.3691 155.203 63.4552 155.078 63.5068C154.953 63.5585 154.816 63.5735 154.682 63.5502C154.549 63.5269 154.425 63.4663 154.325 63.3753C153.319 62.2218 152.558 60.8782 152.086 59.4251C151.417 57.8623 150.734 56.4153 150.05 54.9683C148.669 51.9876 147.302 48.9923 146.008 45.9536C145.735 45.3097 145.46 44.6661 145.186 44.0224Z"
            fill="url(#paint2_radial_14_5482)"
        />
        <path
            d="M112.539 47.2996C117.148 51.901 121.873 56.2998 126.7 60.771C128.165 62.1385 129.674 63.4835 131.193 64.8375L131.195 64.8386C132.119 65.6621 133.046 66.4888 133.97 67.3259L137.663 70.5527C138.66 71.6741 139.877 72.5803 141.24 73.2151C141.719 73.3454 142.301 72.8679 142.097 72.3614C141.373 71.0695 140.382 69.945 139.19 69.0623L135.613 65.7342C133.199 63.4769 130.786 61.2485 128.343 59.0636L127.928 58.6945C123.182 54.4768 118.444 50.2664 113.528 46.3156C112.845 45.7658 111.9 46.6629 112.539 47.2996Z"
            fill="url(#paint3_radial_14_5482)"
        />
        <path
            d="M112.998 81.2946C107.441 79.4077 101.907 77.5286 96.4881 75.4435C95.6739 75.1252 95.9792 73.8663 96.8661 74.1123C102.671 75.6903 108.401 77.4908 114.149 79.2967L115.563 79.741C118.718 80.725 121.844 81.7958 124.97 82.8665L129.608 84.4727C131.135 84.7566 132.583 85.3635 133.854 86.2525C133.939 86.3393 134 86.4468 134.031 86.5645C134.062 86.6821 134.061 86.8056 134.028 86.9228C133.996 87.04 133.933 87.1466 133.846 87.2321C133.76 87.3176 133.652 87.379 133.534 87.4101C132.031 87.4119 130.544 87.0962 129.172 86.484L124.491 85.037C121.336 84.0531 118.195 83.0691 115.069 81.9983C114.38 81.764 113.692 81.5301 113.003 81.2964L112.998 81.2946Z"
            fill="url(#paint4_radial_14_5482)"
        />
        <path
            d="M93.2603 107.596C98.6338 107.333 103.978 106.883 109.324 106.433L109.328 106.433C110.461 106.338 111.595 106.242 112.728 106.149C116.014 105.859 119.299 105.483 122.585 105.107L127.471 104.542C128.968 104.554 130.452 104.263 131.833 103.689C131.947 103.616 132.037 103.512 132.094 103.389C132.151 103.267 132.171 103.13 132.153 102.997C132.135 102.863 132.078 102.737 131.991 102.634C131.903 102.531 131.788 102.455 131.658 102.415C130.157 102.112 128.609 102.112 127.107 102.415L122.339 102.864C119.053 103.139 115.767 103.443 112.467 103.804C111.386 103.931 110.305 104.055 109.223 104.18L109.214 104.181C103.855 104.799 98.4915 105.417 93.1585 106.235C92.3734 106.351 92.5624 107.682 93.333 107.596H93.2603Z"
            fill="url(#paint5_radial_14_5482)"
        />
        <path
            d="M118.075 130.537C113.284 133.193 108.461 135.866 103.598 138.286C103.438 138.368 103.254 138.385 103.082 138.335C102.91 138.284 102.765 138.17 102.675 138.016C102.585 137.862 102.559 137.679 102.6 137.506C102.642 137.333 102.75 137.182 102.9 137.085C107.886 133.856 112.979 130.865 118.072 127.874C118.593 127.568 119.114 127.262 119.634 126.956C122.441 125.278 125.348 123.686 128.256 122.094L132.618 119.736C133.836 118.852 135.228 118.235 136.703 117.927C136.825 117.928 136.945 117.962 137.049 118.023C137.154 118.085 137.241 118.173 137.302 118.278C137.362 118.383 137.393 118.502 137.393 118.624C137.393 118.745 137.36 118.864 137.3 118.969C136.289 120.072 135.055 120.949 133.679 121.545L129.463 124.048L129.284 124.153C126.495 125.796 123.732 127.424 120.885 128.982C119.95 129.498 119.013 130.018 118.075 130.537Z"
            fill="url(#paint6_radial_14_5482)"
        />
        <path
            d="M134.366 150.88C135.363 149.535 136.365 148.184 137.387 146.823C139.379 144.19 141.298 141.513 143.202 138.836L146.11 134.842C147.143 133.721 147.943 132.408 148.466 130.979C148.496 130.846 148.487 130.708 148.44 130.58C148.394 130.452 148.311 130.34 148.202 130.258C148.093 130.175 147.963 130.126 147.827 130.115C147.69 130.104 147.554 130.133 147.433 130.197C146.247 131.104 145.257 132.241 144.525 133.54L141.618 137.447C139.626 140.066 137.634 142.699 135.802 145.376C135.422 145.907 135.042 146.437 134.662 146.968L134.648 146.987C131.252 151.728 127.863 156.458 124.65 161.293C124.214 161.944 125.247 162.74 125.741 162.133C128.77 158.423 131.545 154.681 134.366 150.88Z"
            fill="url(#paint7_radial_14_5482)"
        />
        <path
            d="M159.922 156.185C158.454 162.509 156.971 168.818 155.27 175.083C155.023 175.966 153.772 175.575 153.918 174.707C154.859 169.023 156.005 163.385 157.155 157.732C157.302 157.01 157.449 156.287 157.596 155.563C158.294 152.336 158.992 149.139 159.748 145.941C159.929 145.148 160.114 144.358 160.299 143.568L160.3 143.561C160.486 142.768 160.671 141.976 160.853 141.18C161.032 139.699 161.528 138.273 162.307 136.998C162.393 136.913 162.5 136.852 162.617 136.821C162.735 136.789 162.858 136.789 162.976 136.82C163.093 136.85 163.2 136.911 163.287 136.996C163.374 137.08 163.437 137.186 163.47 137.302C163.606 138.803 163.428 140.316 162.946 141.745L161.987 146.534C161.376 149.761 160.678 152.988 159.922 156.185Z"
            fill="url(#paint8_radial_14_5482)"
        />
        <path
            d="M184.759 158.144L184.757 158.135C184.587 157.259 184.416 156.382 184.246 155.505C183.607 152.279 182.894 149.066 182.167 145.868C181.971 144.973 181.77 144.079 181.57 143.186L181.569 143.182L181.569 143.179C181.413 142.487 181.258 141.796 181.106 141.108C180.953 139.624 180.487 138.189 179.739 136.897C179.419 136.477 178.692 136.752 178.576 137.215C178.428 138.712 178.587 140.223 179.041 141.658C179.347 143.264 179.652 144.856 179.972 146.462C180.597 149.688 181.222 152.915 181.949 156.128C182.33 157.785 182.703 159.443 183.076 161.101L183.078 161.107L183.078 161.109C184.127 165.774 185.176 170.434 186.398 175.054C186.631 175.937 187.852 175.546 187.736 174.692H187.765C186.9 169.144 185.831 163.649 184.761 158.153L184.759 158.144Z"
            fill="url(#paint9_radial_14_5482)"
        />
        <path
            d="M208.85 148.884L208.85 148.884C211.794 153.022 214.743 157.167 217.497 161.409C217.846 162.075 216.901 162.87 216.392 162.248C212.811 157.773 209.389 153.185 205.965 148.594L205.962 148.589L204.645 146.823C202.624 144.175 200.719 141.484 198.829 138.793C198.244 137.947 197.653 137.106 197.064 136.268L197.062 136.265L197.036 136.227C196.697 135.746 196.359 135.265 196.023 134.784C194.988 133.653 194.197 132.324 193.697 130.878C193.667 130.745 193.675 130.606 193.722 130.478C193.769 130.35 193.851 130.239 193.96 130.156C194.069 130.074 194.199 130.024 194.336 130.014C194.472 130.003 194.609 130.032 194.729 130.096C195.916 131.014 196.905 132.161 197.637 133.468L200.545 137.404C202.508 140.037 204.47 142.685 206.36 145.376C207.187 146.546 208.018 147.715 208.85 148.884Z"
            fill="url(#paint10_radial_14_5482)"
        />
        <path
            d="M238.856 137.172C233.952 133.984 228.944 130.983 223.913 127.969L222.223 126.956C219.315 125.263 216.509 123.657 213.63 122.036L209.356 119.634C208.141 118.748 206.755 118.122 205.285 117.797C205.162 117.801 205.043 117.837 204.939 117.9C204.834 117.963 204.748 118.052 204.688 118.158C204.628 118.264 204.597 118.384 204.597 118.506C204.597 118.627 204.629 118.747 204.689 118.853C205.701 119.956 206.927 120.842 208.294 121.458L212.482 123.975C215.317 125.683 218.137 127.376 221.016 128.982C221.513 129.261 222.009 129.541 222.505 129.82L222.543 129.842C227.715 132.754 232.876 135.659 238.158 138.373C238.317 138.454 238.502 138.472 238.673 138.421C238.845 138.371 238.991 138.257 239.081 138.103C239.17 137.949 239.197 137.766 239.155 137.593C239.113 137.419 239.006 137.269 238.856 137.172Z"
            fill="url(#paint11_radial_14_5482)"
        />
        <path
            d="M230.411 103.927C236.569 104.671 242.712 105.414 248.829 106.395H248.771C249.556 106.51 249.353 107.755 248.582 107.755C243.006 107.432 237.462 106.907 231.904 106.38C230.984 106.293 230.064 106.206 229.143 106.12C225.857 105.801 222.572 105.411 219.286 105.006L214.415 104.398C212.915 104.397 211.431 104.092 210.053 103.501C209.939 103.428 209.849 103.324 209.792 103.201C209.735 103.079 209.715 102.942 209.733 102.809C209.751 102.675 209.808 102.549 209.895 102.446C209.983 102.343 210.098 102.267 210.228 102.227C211.731 101.957 213.274 101.992 214.764 102.329L219.664 102.777C222.95 103.081 226.235 103.414 229.521 103.819L230.411 103.927Z"
            fill="url(#paint12_radial_14_5482)"
        />
        <path
            d="M245.049 74.3004C238.754 75.9644 232.516 77.831 226.294 79.7266C223.124 80.696 219.998 81.7379 216.858 82.7942L212.205 84.3569C210.738 84.6577 209.348 85.2535 208.12 86.1078C208.035 86.1951 207.974 86.3027 207.942 86.4203C207.911 86.5379 207.911 86.6614 207.942 86.7791C207.972 86.8968 208.033 87.0047 208.117 87.0924C208.202 87.1801 208.308 87.2446 208.425 87.2798C209.925 87.2826 211.41 86.9772 212.787 86.3827L217.483 84.9357C220.638 83.9807 223.793 83.0112 226.933 82.0417C227.435 81.8741 227.937 81.7067 228.438 81.5393L228.479 81.5257C234.155 79.6314 239.815 77.7421 245.427 75.646C245.606 75.5962 245.758 75.4774 245.85 75.316C245.941 75.1545 245.964 74.9636 245.914 74.7851C245.864 74.6067 245.745 74.4553 245.583 74.3644C245.42 74.2735 245.228 74.2505 245.049 74.3004Z"
            fill="url(#paint13_radial_14_5482)"
        />
        <path
            d="M213.543 59.0635C218.472 54.7949 223.459 50.5263 228.547 46.4458C229.231 45.8959 230.19 46.7786 229.536 47.4152C225.03 51.9134 220.36 56.1385 215.699 60.3557L215.288 60.7275C212.845 62.9125 210.359 65.1119 207.858 67.2534L204.151 70.4657C203.137 71.58 201.905 72.4762 200.53 73.0993C200.065 73.2295 199.469 72.752 199.673 72.2455C200.404 70.9673 201.395 69.8538 202.58 68.9753L206.186 65.6762C208.614 63.4044 211.057 61.2195 213.543 59.0635Z"
            fill="url(#paint14_radial_14_5482)"
        />
        <path
            d="M202.173 27.6058C199.265 33.3938 196.488 39.1383 193.741 45.071C192.514 47.585 191.423 50.1195 190.326 52.666L190.322 52.6737L190.322 52.6745C190.12 53.1445 189.917 53.6149 189.713 54.0857C189.114 55.412 188.514 56.8233 187.925 58.2083L187.925 58.2103L187.765 58.5859C186.989 59.8827 186.493 61.3269 186.311 62.8255C186.317 62.9602 186.362 63.0904 186.439 63.2008C186.517 63.3113 186.625 63.3973 186.75 63.449C186.875 63.5006 187.012 63.5157 187.146 63.4924C187.279 63.4691 187.403 63.4084 187.503 63.3175C188.499 62.1452 189.25 60.7877 189.713 59.3238L191.792 54.9829C193.246 52.0021 194.584 49.0213 195.892 45.9971C196.119 45.4774 196.346 44.958 196.573 44.4387C198.934 39.0293 201.29 33.6335 203.453 28.1412C203.743 27.4033 202.537 26.9113 202.173 27.6058Z"
            fill="url(#paint15_radial_14_5482)"
        />
        <path
            d="M111.144 17.9836C118.704 29.328 126.715 40.3829 134.857 51.2643L137.881 55.3448C138.663 56.6203 139.645 57.7633 140.789 58.7307C140.908 58.7978 141.045 58.8283 141.182 58.8185C141.318 58.8087 141.449 58.759 141.558 58.6756C141.666 58.5923 141.748 58.479 141.792 58.3498C141.836 58.2206 141.841 58.0814 141.807 57.9494C141.347 56.7402 140.689 55.6155 139.858 54.6213L137.227 50.8881C135.436 48.3238 133.657 45.8276 131.869 43.3191L131.178 42.3508C128.305 38.2822 125.311 34.2937 122.319 30.3082C120.906 28.4258 119.492 26.5434 118.093 24.6542C116.705 22.7767 115.262 20.9453 113.817 19.1096C113.279 18.427 112.741 17.7439 112.205 17.0575C111.711 16.4353 110.664 17.2456 111.1 17.9112L111.144 17.9836Z"
            fill="url(#paint16_radial_14_5482)"
        />
        <path
            d="M118.442 69.9883C106.578 63.3032 94.816 56.43 83.3155 49.1951C83.1555 49.1011 83.0396 48.9476 82.9932 48.7685C82.9469 48.5894 82.9739 48.3993 83.0684 48.2401C83.1628 48.0808 83.317 47.9654 83.497 47.9193C83.6769 47.8732 83.8679 47.9001 84.0279 47.9941C84.8654 48.4429 85.7054 48.8879 86.5453 49.3328L86.5464 49.3334L86.5502 49.3354L86.5516 49.3362C88.553 50.3964 90.5539 51.4564 92.5188 52.5666C94.1767 53.497 95.8395 54.4226 97.5031 55.3486C102.23 57.9796 106.963 60.6143 111.609 63.3756L120.521 68.6715L124.461 71.0012C125.626 71.5906 126.695 72.3516 127.631 73.2585C127.734 73.3698 127.799 73.5111 127.816 73.6618C127.832 73.8124 127.8 73.9644 127.724 74.0955C127.647 74.2266 127.531 74.3299 127.391 74.3902C127.251 74.4505 127.096 74.4646 126.948 74.4306C125.503 74.0035 124.134 73.3541 122.891 72.5061L118.442 69.9883Z"
            fill="url(#paint17_radial_14_5482)"
        />
        <path
            d="M70.492 88.9583C84.0425 90.9697 97.6221 92.4456 111.303 93.7623L116.407 94.2543C117.875 94.5191 119.376 94.5532 120.856 94.3556C120.985 94.3156 121.101 94.2397 121.188 94.1367C121.276 94.0337 121.332 93.908 121.35 93.7742C121.369 93.6405 121.348 93.5044 121.292 93.3818C121.235 93.2592 121.144 93.1553 121.03 93.0823C119.799 92.603 118.498 92.3292 117.177 92.2719L112.641 91.7221L102.231 90.4921C97.3918 89.9142 92.5075 89.4645 87.6253 89.015C85.1733 88.7893 82.7219 88.5635 80.2769 88.3217C77.4783 88.0558 74.6797 87.8675 71.8812 87.6791C71.4814 87.6522 71.0808 87.6253 70.681 87.5982C69.9105 87.5982 69.7215 88.8426 70.492 88.9583Z"
            fill="url(#paint18_radial_14_5482)"
        />
        <path
            d="M114.226 118.405C101.388 122.702 88.3026 126.84 75.2174 130.472C75.1286 130.496 75.036 130.502 74.9448 130.49C74.8537 130.479 74.7658 130.449 74.6861 130.403C74.6065 130.358 74.5366 130.297 74.4806 130.224C74.4246 130.152 74.3835 130.069 74.3596 129.98C74.3357 129.892 74.3296 129.8 74.3415 129.709C74.3534 129.618 74.3832 129.531 74.4291 129.452C74.475 129.372 74.5361 129.303 74.609 129.247C74.6819 129.191 74.7651 129.15 74.8539 129.127C75.417 128.922 75.9801 128.716 76.5433 128.51C79.0052 127.611 81.4707 126.71 83.9554 125.885C86.0641 125.186 88.1714 124.474 90.2807 123.762L90.2816 123.762C95.1092 122.132 99.9468 120.498 104.834 118.998L114.735 115.959L119.097 114.613C120.314 114.139 121.6 113.861 122.906 113.789C123.057 113.792 123.203 113.843 123.322 113.936C123.441 114.028 123.527 114.157 123.566 114.302C123.605 114.447 123.596 114.601 123.54 114.741C123.484 114.881 123.384 114.998 123.255 115.076C121.965 115.866 120.556 116.442 119.082 116.784L114.226 118.405Z"
            fill="url(#paint19_radial_14_5482)"
        />
        <path
            d="M96.5026 166.445C107.014 157.763 117.148 148.719 127.195 139.56L130.975 136.116C132.17 135.217 133.207 134.126 134.043 132.889C134.304 132.411 133.854 131.63 133.258 131.862C132.085 132.45 131.015 133.222 130.088 134.148L126.7 137.172L118.806 144.031C114.987 147.353 111.258 150.784 107.533 154.212L107.532 154.213C105.854 155.757 104.177 157.301 102.493 158.833C100.123 160.99 97.8256 163.174 95.5139 165.461C94.8596 166.097 95.8192 166.994 96.5026 166.445Z"
            fill="url(#paint20_radial_14_5482)"
        />
        <path
            d="M147.651 153.755C142.112 166.257 136.587 178.657 130.495 190.855C130.132 191.55 128.91 191.043 129.216 190.32C129.504 189.611 129.79 188.901 130.077 188.189C130.991 185.923 131.906 183.653 132.88 181.45C133.767 179.421 134.644 177.386 135.521 175.351C137.547 170.653 139.573 165.952 141.734 161.308L146.096 151.83L148.015 147.706C148.47 146.494 149.124 145.364 149.948 144.364C150.049 144.273 150.173 144.212 150.306 144.189C150.439 144.165 150.577 144.18 150.702 144.232C150.827 144.284 150.935 144.37 151.012 144.48C151.09 144.591 151.135 144.721 151.141 144.856C150.887 146.337 150.396 147.768 149.687 149.095C149.003 150.643 148.32 152.206 147.651 153.755Z"
            fill="url(#paint21_radial_14_5482)"
        />
        <path
            d="M171.757 199.277C172.342 185.987 172.227 172.614 172.113 159.308L172.106 158.544V153.45C172.223 151.971 172.11 150.482 171.772 149.037C171.725 148.896 171.634 148.773 171.513 148.686C171.391 148.599 171.245 148.552 171.096 148.552C170.946 148.552 170.8 148.599 170.679 148.686C170.558 148.773 170.467 148.896 170.42 149.037C170.083 150.301 169.955 151.611 170.042 152.915V157.488C170.042 159.683 170 161.878 169.959 164.07C169.936 165.316 169.912 166.561 169.896 167.805C169.828 172.63 169.89 177.507 169.951 182.379C169.982 184.812 170.013 187.245 170.027 189.669C170.027 191.921 170.114 194.173 170.2 196.43C170.237 197.377 170.273 198.327 170.303 199.277C170.303 199.468 170.38 199.652 170.516 199.788C170.653 199.924 170.838 200 171.03 200C171.223 200 171.408 199.924 171.544 199.788C171.681 199.652 171.757 199.468 171.757 199.277Z"
            fill="url(#paint22_radial_14_5482)"
        />
        <path
            d="M196.343 152.756C202.013 165.215 207.538 177.702 212.569 190.378L212.612 190.436C212.903 191.174 211.682 191.68 211.333 190.971C210.952 190.182 210.568 189.392 210.184 188.603C209.161 186.498 208.137 184.394 207.175 182.289C206.245 180.253 205.305 178.219 204.364 176.185L204.362 176.181C202.228 171.566 200.091 166.946 198.073 162.277L193.959 152.684C193.854 152.445 193.749 152.208 193.645 151.972L193.635 151.95L193.635 151.949L193.633 151.945C193.135 150.816 192.645 149.705 192.156 148.516C191.544 147.348 191.131 146.086 190.934 144.783C190.94 144.648 190.985 144.518 191.063 144.408C191.14 144.297 191.248 144.211 191.373 144.16C191.498 144.108 191.636 144.093 191.769 144.116C191.903 144.14 192.027 144.2 192.127 144.291C193.023 145.452 193.729 146.746 194.22 148.126C194.684 149.19 195.184 150.262 195.684 151.335C195.904 151.808 196.125 152.282 196.343 152.756Z"
            fill="url(#paint23_radial_14_5482)"
        />
        <path
            d="M246.314 165.518C236.544 155.968 226.41 146.867 216.16 137.838L212.351 134.481C211.306 133.397 210.094 132.489 208.759 131.789C208.163 131.543 207.713 132.339 207.974 132.817C208.686 133.914 209.569 134.891 210.591 135.711L213.935 138.778L221.539 145.796C225.568 149.513 229.721 153.115 233.854 156.701L233.856 156.702C235.226 157.891 236.593 159.077 237.954 160.266C239.842 161.929 241.774 163.54 243.708 165.153L243.716 165.159C244.253 165.606 244.789 166.054 245.325 166.502C246.009 167.067 246.968 166.155 246.314 165.518Z"
            fill="url(#paint24_radial_14_5482)"
        />
        <path
            d="M228.3 116.249C241.298 120.286 254.296 124.569 267.061 129.3C267.89 129.604 267.57 130.892 266.683 130.646C266.034 130.46 265.385 130.276 264.735 130.091C262.298 129.399 259.858 128.706 257.437 127.94C255.152 127.218 252.861 126.511 250.569 125.802L250.568 125.802C245.831 124.338 241.092 122.874 236.398 121.284L226.483 117.913L222.121 116.466C220.857 116.124 219.655 115.587 218.559 114.874C218.05 114.469 218.515 113.673 219.053 113.673C220.553 113.789 222.025 114.141 223.415 114.715L228.3 116.249Z"
            fill="url(#paint25_radial_14_5482)"
        />
        <path
            d="M271.321 87.7718C257.655 88.6111 243.959 89.9712 230.365 91.505L225.305 92.0694C223.815 92.1258 222.344 92.4138 220.943 92.9231C220.829 92.995 220.738 93.0978 220.68 93.2195C220.622 93.3412 220.6 93.4767 220.617 93.6103C220.634 93.7438 220.689 93.8699 220.775 93.9737C220.861 94.0775 220.975 94.1547 221.103 94.1964C222.389 94.4062 223.7 94.4062 224.985 94.1964L229.55 93.7913L240.004 92.8507C245.024 92.3911 250.086 91.7935 255.146 91.196C257.413 90.9283 259.681 90.6605 261.944 90.4053C264.543 90.1231 267.122 89.755 269.706 89.3863L269.709 89.3859C270.304 89.3009 270.9 89.2159 271.496 89.132C272.281 89.0307 272.092 87.685 271.321 87.7718Z"
            fill="url(#paint26_radial_14_5482)"
        />
        <path
            d="M222.31 68.035C234.058 61.1763 245.892 54.4189 257.96 48.1534C258.12 48.0594 258.311 48.0325 258.491 48.0786C258.671 48.1248 258.825 48.2402 258.92 48.3994C259.014 48.5587 259.041 48.7488 258.995 48.9279C258.948 49.107 258.832 49.2604 258.672 49.3544C258.008 49.7659 257.346 50.179 256.683 50.592C254.62 51.8784 252.56 53.1632 250.458 54.3899C248.773 55.3821 247.089 56.3827 245.403 57.3843C240.82 60.1076 236.223 62.8386 231.557 65.4305L222.484 70.466L218.486 72.6943C217.393 73.4156 216.19 73.9578 214.924 74.3005C214.776 74.3416 214.618 74.3327 214.475 74.2754C214.333 74.218 214.213 74.1153 214.135 73.9832C214.057 73.8512 214.025 73.6972 214.044 73.5452C214.063 73.3932 214.133 73.2518 214.241 73.1429C215.337 72.1126 216.588 71.2583 217.948 70.6107L222.31 68.035Z"
            fill="url(#paint27_radial_14_5482)"
        />
        <path
            d="M229.798 17.2022C221.278 27.8665 213.179 38.936 205.212 49.991L202.304 54.1438C201.315 55.2511 200.514 56.5122 199.934 57.8771C199.903 58.0089 199.91 58.147 199.954 58.2751C199.999 58.4031 200.08 58.5156 200.187 58.5994C200.294 58.6831 200.423 58.7346 200.559 58.7476C200.694 58.7606 200.831 58.7347 200.952 58.6729C201.979 57.8543 202.867 56.8773 203.584 55.7789C204.485 54.5634 205.401 53.348 206.317 52.147L212.598 43.7979C215.582 39.7888 218.498 35.6984 221.415 31.6055L221.418 31.6015C222.764 29.7129 224.111 27.8239 225.465 25.942C227.044 23.7442 228.561 21.4943 230.076 19.2453C230.347 18.8439 230.617 18.4425 230.888 18.0415C231.324 17.3903 230.292 16.5945 229.798 17.2022Z"
            fill="url(#paint28_radial_14_5482)"
        />
        <path
            d="M182.313 40.1805C185.032 26.8971 187.925 13.6572 191.327 0.532998C191.545 -0.349665 192.825 0.0410219 192.665 0.894744C192.127 4.06365 191.574 7.23255 190.934 10.3002C190.304 13.2474 189.715 16.2021 189.127 19.156C188.291 23.3519 187.456 27.5462 186.5 31.7156L184.188 41.8445C184.13 42.1038 184.072 42.3639 184.014 42.6244C183.737 43.8613 183.459 45.106 183.17 46.3012C182.982 47.582 182.584 48.8235 181.993 49.9766C181.911 50.1019 181.791 50.1982 181.651 50.2518C181.511 50.3054 181.357 50.3136 181.212 50.2751C181.067 50.2365 180.938 50.1533 180.843 50.0374C180.748 49.9214 180.693 49.7786 180.684 49.6293C180.657 48.1213 180.863 46.6182 181.295 45.1726C181.462 44.3405 181.633 43.5085 181.804 42.6765C181.975 41.8445 182.145 41.0125 182.313 40.1805Z"
            fill="url(#paint29_radial_14_5482)"
        />
        <path
            d="M74.8307 63.0538C74.7815 63.0887 74.7445 63.1382 74.7247 63.1952V63.1972L74.2102 64.7443C73.98 65.4368 73.4374 65.9792 72.7447 66.2094L71.1952 66.7258C71.1382 66.7456 71.0888 66.7826 71.0538 66.8317C71.0188 66.8809 71 66.9397 71 67C71 67.0603 71.0188 67.1191 71.0538 67.1683C71.0888 67.2174 71.1382 67.2544 71.1952 67.2742L72.7427 67.7906C73.0841 67.9041 73.3943 68.0957 73.6487 68.35C73.9031 68.6043 74.0946 68.9144 74.2082 69.2557L74.7247 70.8048C74.7445 70.8618 74.7815 70.9113 74.8307 70.9462C74.8798 70.9812 74.9387 71 74.999 71C75.0593 71 75.1182 70.9812 75.1673 70.9462C75.2165 70.9113 75.2535 70.8618 75.2733 70.8048L75.7898 69.2557C75.9033 68.9144 76.0949 68.6043 76.3493 68.35C76.6037 68.0957 76.9139 67.9041 77.2553 67.7906L78.8048 67.2742C78.8618 67.2544 78.9112 67.2174 78.9462 67.1683C78.9812 67.1191 79 67.0603 79 67C79 66.9397 78.9812 66.8809 78.9462 66.8317C78.9112 66.7826 78.8618 66.7456 78.8048 66.7258L77.2553 66.2094C76.5626 65.9792 76.02 65.4368 75.7898 64.7443L75.2733 63.1952C75.2535 63.1382 75.2165 63.0887 75.1673 63.0538C75.1182 63.0188 75.0593 63 74.999 63C74.9387 63 74.8798 63.0188 74.8307 63.0538Z"
            fill="#F7B272"
        />
        <path
            d="M154.661 168.108C154.563 168.177 154.489 168.276 154.449 168.39V168.394L153.42 171.489C152.96 172.874 151.875 173.958 150.489 174.419L147.39 175.452C147.276 175.491 147.178 175.565 147.108 175.663C147.038 175.762 147 175.879 147 176C147 176.121 147.038 176.238 147.108 176.337C147.178 176.435 147.276 176.509 147.39 176.548L150.485 177.581C151.168 177.808 151.789 178.191 152.297 178.7C152.806 179.209 153.189 179.829 153.416 180.511L154.449 183.61C154.489 183.724 154.563 183.823 154.661 183.892C154.76 183.962 154.877 184 154.998 184C155.119 184 155.236 183.962 155.335 183.892C155.433 183.823 155.507 183.724 155.547 183.61L156.58 180.511C156.807 179.829 157.19 179.209 157.699 178.7C158.207 178.191 158.828 177.808 159.511 177.581L162.61 176.548C162.724 176.509 162.822 176.435 162.892 176.337C162.962 176.238 163 176.121 163 176C163 175.879 162.962 175.762 162.892 175.663C162.822 175.565 162.724 175.491 162.61 175.452L159.511 174.419C158.125 173.958 157.04 172.874 156.58 171.489L155.547 168.39C155.507 168.276 155.433 168.177 155.335 168.108C155.236 168.038 155.119 168 154.998 168C154.877 168 154.76 168.038 154.661 168.108Z"
            fill="#F7B272"
        />
        <path
            d="M249.492 14.1613C249.345 14.2662 249.233 14.4145 249.174 14.5855V14.5915L247.631 19.2329C246.94 21.3105 245.312 22.9377 243.234 23.6282L238.586 25.1774C238.415 25.2367 238.266 25.3477 238.161 25.4952C238.056 25.6426 238 25.819 238 26C238 26.181 238.056 26.3574 238.161 26.5048C238.266 26.6523 238.415 26.7633 238.586 26.8226L243.228 28.3718C244.252 28.7124 245.183 29.287 245.946 30.0499C246.709 30.8129 247.284 31.7432 247.625 32.7671L249.174 37.4145C249.233 37.5855 249.345 37.7338 249.492 37.8387C249.639 37.9436 249.816 38 249.997 38C250.178 38 250.354 37.9436 250.502 37.8387C250.649 37.7338 250.761 37.5855 250.82 37.4145L252.369 32.7671C252.71 31.7432 253.285 30.8129 254.048 30.0499C254.811 29.287 255.742 28.7124 256.766 28.3718L261.414 26.8226C261.585 26.7633 261.734 26.6523 261.839 26.5048C261.944 26.3574 262 26.181 262 26C262 25.819 261.944 25.6426 261.839 25.4952C261.734 25.3477 261.585 25.2367 261.414 25.1774L256.766 23.6282C254.688 22.9377 253.06 21.3105 252.369 19.2329L250.82 14.5855C250.761 14.4145 250.649 14.2662 250.502 14.1613C250.354 14.0564 250.178 14 249.997 14C249.816 14 249.639 14.0564 249.492 14.1613Z"
            fill="#F7B272"
        />
        <g clip-path="url(#clip0_14_5482)">
            <path
                d="M170.997 159.994C204.132 159.994 230.994 133.132 230.994 99.997C230.994 66.8616 204.132 40 170.997 40C137.862 40 111 66.8616 111 99.997C111 133.132 137.862 159.994 170.997 159.994Z"
                fill="#F94F55"
            />
            <path
                d="M123.766 75.0814L122.793 76.8049L121.486 76.0671L124.238 71.1929L125.544 71.9307L124.577 73.6481L129.263 76.2546L128.441 77.7181L123.766 75.0814Z"
                fill="white"
            />
            <path
                d="M128.901 72.2693L125.327 69.554L126.343 68.2175L129.862 70.8905C130.322 71.2413 130.896 71.2836 131.295 70.7575C131.695 70.2313 131.501 69.6931 131.041 69.3424L127.522 66.6694L128.538 65.3329L132.112 68.0482C133.364 68.9977 133.503 70.3704 132.511 71.6767C131.519 72.9829 130.158 73.2188 128.913 72.2693H128.901Z"
                fill="white"
            />
            <path
                d="M137.875 65.5082L134.839 64.7946L134.464 65.1635L136.315 67.0443L135.117 68.2175L130.31 63.3251L132.384 61.2871C133.358 60.3316 134.628 60.2953 135.523 61.2085C136.097 61.7951 136.224 62.6055 135.861 63.4279L139.242 64.1536L137.869 65.5022L137.875 65.5082ZM133.473 64.1597L134.289 63.3432C134.603 63.0348 134.597 62.6659 134.325 62.3877C134.071 62.1277 133.708 62.1096 133.382 62.4361L132.565 63.2404L133.473 64.1657V64.1597Z"
                fill="white"
            />
            <path
                d="M135.22 58.59L136.629 57.5437L140.675 60.4586L139.097 55.7235L140.506 54.6772L142.598 61.6681L141.256 62.6599L135.208 58.59H135.22Z"
                fill="white"
            />
            <path
                d="M147.515 57.0478L145.531 58.1424L145.846 59.5273L144.334 60.3618L142.931 53.2923L144.763 52.2764L150.018 57.2111L148.507 58.0457L147.503 57.0418L147.515 57.0478ZM146.469 56.0137L144.63 54.1934L145.205 56.7092L146.469 56.0137Z"
                fill="white"
            />
            <path
                d="M148.482 50.4743L150.012 49.8695L152.008 54.8224L154.487 53.8366L155.05 55.2518L151.01 56.8543L148.482 50.4743Z"
                fill="white"
            />
            <path
                d="M154.385 48.2911L155.975 47.8617L157.39 53.0141L159.966 52.3247L160.359 53.7943L156.163 54.9191L154.379 48.2911H154.385Z"
                fill="white"
            />
            <path
                d="M160.402 46.8518L162.053 46.5736L163.202 53.3407L161.551 53.6189L160.408 46.8518H160.402Z"
                fill="white"
            />
            <path
                d="M164.677 46.1442L166.256 46.0717L169.491 50.1114L169.292 45.9265L170.852 45.8539L171.172 52.7118L169.594 52.7843L166.371 48.7325L166.564 52.9234L164.998 52.996L164.677 46.1382V46.1442Z"
                fill="white"
            />
            <path
                d="M173.658 45.9991L178.417 46.495L178.26 48.0068L175.073 47.6742L174.958 48.793L177.686 49.0772L177.54 50.4803L174.813 50.196L174.68 51.472L177.909 51.8107L177.752 53.3226L172.956 52.8206L173.664 45.9991H173.658Z"
                fill="white"
            />
            <path
                d="M180.969 46.9122L182.499 47.3114L184.386 52.1312L185.444 48.0794L186.95 48.4725L185.221 55.1126L183.691 54.7135L181.816 49.8876L180.758 53.9454L179.24 53.5524L180.969 46.9122Z"
                fill="white"
            />
            <path
                d="M192.423 50.5407L193.82 51.2725L194.256 54.4958L197.146 53.0081L198.543 53.7398L195.368 59.8236L193.971 59.0918L195.943 55.3122L193.79 56.4309L192.937 55.9895L192.623 53.5826L190.651 57.3623L189.254 56.6305L192.429 50.5468L192.423 50.5407Z"
                fill="white"
            />
            <path
                d="M200.533 61.8919L198.719 60.5372L197.588 61.396L196.209 60.3619L202.075 56.177L203.756 57.4349L201.416 64.2504L200.031 63.2163L200.533 61.8919ZM201.047 60.513L201.948 58.0941L199.892 59.6543L201.047 60.5191V60.513Z"
                fill="white"
            />
            <path
                d="M207.016 60.1139L208.201 61.2992L206.151 63.3493L209.798 62.8957L211.158 64.2564L206.883 64.6918L206.32 69.1186L204.972 67.7639L205.48 64.0266L203.357 66.1493L202.172 64.964L207.028 60.1139H207.016Z"
                fill="white"
            />
            <path
                d="M207.179 69.0702L208.612 68.6711C208.643 69.5056 208.763 70.1708 209.187 70.7211C209.562 71.211 209.943 71.2957 210.209 71.09C210.396 70.9449 210.481 70.7332 210.372 70.3523L209.985 68.9674C209.743 68.0965 209.9 67.2862 210.572 66.7721C211.563 66.0162 212.833 66.1493 213.874 67.516C214.321 68.1026 214.545 68.6952 214.66 69.2093L213.426 70.1466C213.287 69.4149 213.087 68.9009 212.694 68.3868C212.325 67.903 211.969 67.8184 211.739 67.9877C211.606 68.0905 211.515 68.2417 211.6 68.5501L211.969 69.8443C212.253 70.8481 212.09 71.7553 211.285 72.3661C210.215 73.1764 208.933 72.8015 207.995 71.5678C207.403 70.7877 207.106 69.7173 207.185 69.0581L207.179 69.0702Z"
                fill="white"
            />
            <path
                d="M212.428 73.4304L216.335 71.217L217.163 72.6745L213.317 74.8576C212.815 75.1419 212.549 75.6498 212.876 76.2243C213.202 76.7928 213.771 76.8291 214.279 76.5449L218.125 74.3617L218.953 75.8192L215.047 78.0325C213.68 78.8066 212.368 78.3893 211.557 76.9682C210.753 75.547 211.068 74.1984 212.434 73.4244L212.428 73.4304Z"
                fill="white"
            />
            <path
                d="M128.792 133.155L130.195 132.659C130.286 133.494 130.449 134.141 130.902 134.667C131.308 135.133 131.695 135.187 131.949 134.97C132.124 134.812 132.197 134.601 132.063 134.22L131.586 132.865C131.283 132.012 131.386 131.19 132.021 130.633C132.958 129.817 134.235 129.859 135.365 131.154C135.849 131.71 136.115 132.284 136.26 132.792L135.093 133.808C134.906 133.089 134.67 132.593 134.247 132.103C133.847 131.643 133.485 131.583 133.267 131.77C133.14 131.879 133.061 132.043 133.164 132.339L133.618 133.609C133.968 134.595 133.872 135.508 133.11 136.173C132.1 137.056 130.787 136.766 129.771 135.598C129.124 134.861 128.762 133.814 128.792 133.149V133.155Z"
                fill="white"
            />
            <path
                d="M134.579 137.147L137.536 133.766L138.8 134.873L135.891 138.199C135.51 138.634 135.432 139.203 135.922 139.632C136.418 140.068 136.968 139.91 137.349 139.475L140.264 136.149L141.528 137.255L138.57 140.636C137.536 141.815 136.158 141.858 134.924 140.781C133.69 139.705 133.551 138.332 134.585 137.147H134.579Z"
                fill="white"
            />
            <path
                d="M138.468 143.4L139.254 142.251C139.369 142.396 139.514 142.535 139.713 142.674C140.143 142.97 140.572 142.976 140.899 142.499L143.535 138.646L144.92 139.596L142.272 143.472C141.383 144.772 140.161 144.821 139.097 144.089C138.77 143.865 138.54 143.581 138.474 143.4H138.468Z"
                fill="white"
            />
            <path
                d="M144.183 144.476L146.263 140.497L147.751 141.277L145.7 145.196C145.434 145.71 145.489 146.278 146.069 146.587C146.65 146.889 147.152 146.611 147.418 146.097L149.468 142.178L150.956 142.958L148.875 146.937C148.15 148.328 146.819 148.691 145.368 147.935C143.916 147.173 143.457 145.873 144.189 144.482L144.183 144.476Z"
                fill="white"
            />
            <path
                d="M151.657 143.309L153.314 143.896L153.163 148.879L156.205 144.924L157.862 145.51L153.242 151.153L151.669 150.596L151.657 143.315V143.309Z"
                fill="white"
            />
            <path
                d="M160.583 151.679L158.37 151.207L157.705 152.459L156.017 152.102L159.592 145.843L161.642 146.278L162.373 153.451L160.686 153.094L160.583 151.679ZM160.474 150.215L160.281 147.639L159.065 149.919L160.474 150.221V150.215Z"
                fill="white"
            />
            <path
                d="M167.901 148.522L165.923 148.461L165.972 146.962L171.566 147.137L171.517 148.637L169.546 148.576L169.407 153.94L167.731 153.886L167.901 148.522Z"
                fill="white"
            />
            <path
                d="M172.315 150.475C172.086 148.407 173.307 146.883 175.363 146.653C177.42 146.423 178.943 147.645 179.173 149.713C179.415 151.902 178.206 153.517 176.15 153.747C174.093 153.977 172.557 152.664 172.315 150.475ZM177.486 149.901C177.353 148.703 176.543 148.05 175.527 148.165C174.517 148.28 173.864 149.09 173.997 150.288C174.142 151.6 174.958 152.35 175.98 152.235C177.002 152.12 177.631 151.213 177.486 149.901Z"
                fill="white"
            />
            <path
                d="M179.959 146.061L181.592 145.686L183.14 152.368L181.508 152.749L179.959 146.067V146.061Z"
                fill="white"
            />
            <path
                d="M183.255 145.353L184.737 144.803L187.416 146.647L188.25 143.502L189.732 142.952L192.115 149.387L190.633 149.937L189.152 145.94L188.535 148.292L187.634 148.625L185.638 147.24L187.12 151.237L185.638 151.788L183.255 145.353Z"
                fill="white"
            />
            <path d="M191.196 142.124L192.683 141.35L195.864 147.427L194.377 148.201L191.196 142.124Z" fill="white" />
            <path
                d="M196.148 141.041L194.498 142.13L193.675 140.878L198.35 137.8L199.172 139.052L197.527 140.134L200.503 144.597L199.106 145.522L196.155 141.047L196.148 141.041Z"
                fill="white"
            />
            <path
                d="M202.014 140.618L199.057 137.237L200.321 136.131L203.236 139.457C203.617 139.892 204.167 140.049 204.663 139.614C205.159 139.179 205.074 138.61 204.693 138.181L201.779 134.855L203.043 133.748L206 137.128C207.034 138.308 206.895 139.68 205.661 140.763C204.427 141.839 203.049 141.797 202.014 140.618Z"
                fill="white"
            />
            <path
                d="M208.376 137.056L207.693 135.738C208.503 135.538 209.126 135.284 209.586 134.758C209.991 134.292 209.991 133.905 209.743 133.681C209.568 133.524 209.338 133.488 208.987 133.669L207.711 134.328C206.907 134.746 206.078 134.752 205.443 134.201C204.506 133.385 204.373 132.115 205.498 130.821C205.982 130.265 206.514 129.926 206.998 129.708L208.165 130.724C207.475 131.008 207.016 131.311 206.592 131.801C206.193 132.26 206.181 132.629 206.399 132.817C206.526 132.925 206.695 132.986 206.979 132.841L208.171 132.218C209.096 131.734 210.015 131.71 210.777 132.369C211.787 133.252 211.678 134.582 210.662 135.756C210.021 136.493 209.036 137.001 208.37 137.062L208.376 137.056Z"
                fill="white"
            />
            <path
                d="M158.872 117.468H162.482C165.627 117.468 167.52 119.319 167.52 122.1C167.52 125.04 165.639 127.005 162.482 127.005H158.872V117.474V117.468ZM162.476 124.894C164.163 124.894 165.173 123.794 165.173 122.1C165.173 120.546 164.151 119.579 162.476 119.579H161.224V124.888H162.476V124.894Z"
                fill="white"
            />
            <path
                d="M168.76 117.486C168.76 116.736 169.292 116.222 170.042 116.222C170.791 116.222 171.293 116.755 171.293 117.486C171.293 118.218 170.791 118.75 170.042 118.75C169.292 118.75 168.76 118.23 168.76 117.486ZM168.923 119.47H171.1L171.112 126.999H168.917V119.47H168.923Z"
                fill="white"
            />
            <path
                d="M172.775 117.486C172.775 116.736 173.307 116.222 174.057 116.222C174.807 116.222 175.309 116.755 175.309 117.486C175.309 118.218 174.807 118.75 174.057 118.75C173.307 118.75 172.775 118.23 172.775 117.486ZM172.938 119.47H175.115L175.127 126.999H172.932V119.47H172.938Z"
                fill="white"
            />
            <path d="M177.159 116.791H179.337V127.005H177.159V116.791Z" fill="white" />
            <path
                d="M181.205 117.486C181.205 116.736 181.737 116.222 182.487 116.222C183.237 116.222 183.739 116.755 183.739 117.486C183.739 118.218 183.237 118.75 182.487 118.75C181.737 118.75 181.205 118.23 181.205 117.486ZM181.368 119.47H183.546L183.558 126.999H181.362V119.47H181.368Z"
                fill="white"
            />
            <path
                d="M202.275 81.026C202.275 83.2334 200.557 84.9266 198.35 84.9266C196.142 84.9266 194.449 83.2334 194.449 81.026C194.449 78.8187 196.142 77.077 198.35 77.077C200.557 77.077 202.275 78.8187 202.275 81.026ZM165.252 87.648C158.709 87.648 153.877 92.3892 153.877 98.7451C153.877 105.101 158.703 109.842 165.252 109.842C171.801 109.842 176.67 105.101 176.67 98.7451C176.67 92.3892 171.819 87.648 165.252 87.648ZM165.252 92.9395C168.451 92.9395 170.864 95.3343 170.864 98.7512C170.864 102.168 168.451 104.563 165.252 104.563C162.053 104.563 159.664 102.168 159.664 98.7512C159.664 95.3343 162.059 92.9395 165.252 92.9395ZM190.149 87.648C187.561 87.648 185.541 88.537 184.211 90.5206V88.2165H178.708V109.304H184.386V97.8138C184.386 94.6812 186.835 93.2661 189.756 93.2661C192.199 93.2661 193.113 93.7439 193.113 93.7439V88.2165C193.113 88.2165 191.915 87.648 190.155 87.648M201.222 88.2165H195.544V109.304H201.222V88.2165ZM152.353 88.2165H144.721V82.5379H139.042V88.2165H134.978V93.2661H139.042V101.424C139.042 106.885 141.715 109.842 146.94 109.842C149.94 109.842 152.28 108.814 154.052 107.532L151.669 103.281C150.514 103.976 149.226 104.551 147.878 104.551C146.118 104.551 144.727 103.595 144.727 100.958V93.254H152.359V88.2044L152.353 88.2165Z"
                fill="white"
            />
            <path
                d="M128.066 129.92C127.752 129.92 127.437 129.769 127.25 129.484C121.444 120.891 118.372 110.84 118.372 100.432C118.372 93.1693 119.841 86.1422 122.744 79.5444C122.962 79.0485 123.543 78.8187 124.044 79.0424C124.54 79.2602 124.77 79.8407 124.546 80.3427C121.759 86.6865 120.343 93.4475 120.343 100.438C120.343 110.459 123.295 120.123 128.882 128.39C129.185 128.837 129.07 129.454 128.616 129.757C128.447 129.871 128.254 129.926 128.066 129.926V129.92Z"
                fill="white"
            />
            <path
                d="M212.15 130.549C211.957 130.549 211.757 130.494 211.588 130.373C211.14 130.065 211.031 129.448 211.346 129.001C217.206 120.595 220.308 110.719 220.308 100.432C220.308 93.6653 218.978 87.1038 216.359 80.9293C216.148 80.4274 216.383 79.8528 216.879 79.6412C217.381 79.4295 217.956 79.6654 218.167 80.1613C220.889 86.5837 222.274 93.3992 222.274 100.432C222.274 111.124 219.05 121.387 212.961 130.125C212.767 130.398 212.465 130.549 212.15 130.549Z"
                fill="white"
            />
        </g>
        <defs>
            <radialGradient
                id="paint0_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint2_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint3_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint4_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint5_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint6_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint7_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint8_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint9_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint10_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint11_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint12_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint13_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint14_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint15_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint16_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint17_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint18_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint19_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint20_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint21_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint22_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint23_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint24_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint25_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint26_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint27_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint28_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <radialGradient
                id="paint29_radial_14_5482"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 100) rotate(90) scale(100 101)"
            >
                <stop offset="0.536458" stop-color="#FBD6B4" stop-opacity="0" />
                <stop offset="1" stop-color="#FBD6B4" />
            </radialGradient>
            <clipPath id="clip0_14_5482">
                <rect width="120" height="120" fill="white" transform="translate(111 40)" />
            </clipPath>
        </defs>
    </svg>`;
