import WarpElement from '@warp-ds/elements-core';

class BrowserWarning extends WarpElement {
    constructor() {
        super();
        this._translationSupport = false;
    }

    static get properties() {
        return {
            enabled: { type: Boolean },
        };
    }

    connectedCallback() {
        super.connectedCallback();

        /**
         * Render broadcast warnings for unsupported browsers
         */
        window.addEventListener('load', () => {
            try {
                // the warning will only be rendered if the page has an element with an id of `broadcast`
                // this is to ensure we render in the correct place, and don't mess with someones layout
                const container = document.querySelector('f-broadcast') || document.querySelector('#broadcast');
                if (!container) {
                    return;
                }

                // @ts-ignore
                if (!this.enabled) {
                    return;
                }

                // See if the browser supports CSS custom properties https://stackoverflow.com/a/38012166/966362
                if (window.CSS && window.CSS.supports('color', 'var(--detect)')) {
                    return;
                }

                const storageKey = 'dismissedBrowserVersionWarning';

                // Make sure we don't rerender on the next pages if the user has already dimissed it
                const hasDismissed = window.sessionStorage.getItem(storageKey) === 'true';

                if (hasDismissed) return;

                const broadcastElement = document.createElement('section');
                broadcastElement.className = 'broadcast';
                broadcastElement.setAttribute(
                    'aria-label',
                    this.i18n.t({
                        id: 'browser-warning.broadcast.aria-label',
                        message: 'Important message',
                        comment: 'aria-label for broadcast warning',
                    }),
                );

                // Message
                const content = document.createElement('div');
                content.className = 'broadcast__content';
                content.innerHTML = `
            <p>${this.i18n.t({
                id: 'browser-warning.broadcast.content.hello',
                message:
                    "Hey! It looks like you're using an old browser version. To make sure FINN works well for you, please update your browser",
                comment: 'First part of the content of the broadcast warning',
            })}</p>
            <p>${this.i18n.t({
                id: 'browser-warning.broadcast.content.expiration',
                message:
                    'From January 1st 2021 FINN might not work for you at all, as we will have discontinued support for old browsers',
                comment: 'Second part of the content of the broadcast warning',
            })}</p>
            <p>${this.i18n.t({
                id: 'browser-warning.broadcast.content.upgrade',
                message: 'Want to try a brand new browser? Here are some suggestions',
                comment:
                    'Third part of the broadcast warning content. Is followed by a list with links to popular browsers',
            })}
                <a class="u-display-block u-mt16" href="https://www.google.com/intl/no/chrome/">Google Chrome</a>
                <a class="u-display-block u-mt8" href="https://www.microsoft.com/nb-no/edge">Microsoft Edge</a>
                <a class="u-display-block u-mt8" href="https://www.mozilla.org/nb-NO/firefox/new/">Mozilla Firefox</a>
            </p>
                `;

                broadcastElement.appendChild(content);
                // end Message

                // Button
                const icon = document.createElement('span');
                icon.className = 'icon icon--cross';

                const dismissButtonElement = document.createElement('button');
                dismissButtonElement.className =
                    'button button--pill button--small button--has-icon broadcast__dismiss';
                dismissButtonElement.type = 'button';
                dismissButtonElement.setAttribute(
                    'aria-label',
                    this.i18n.t({
                        id: 'browser-warning.broadcast.dismiss.aria-label',
                        message: 'Dismiss',
                        comment: 'aria-label for dismiss button in the browser warning broadcast',
                    }),
                );

                const listener = () => {
                    broadcastElement.remove();
                    dismissButtonElement.removeEventListener('click', listener);

                    try {
                        window.sessionStorage.setItem(storageKey, 'true');
                    } catch (e) {
                        console.error(e);
                    }
                };

                dismissButtonElement.addEventListener('click', listener);
                dismissButtonElement.appendChild(icon);

                broadcastElement.appendChild(dismissButtonElement);
                // End button
                container.appendChild(broadcastElement);
            } catch (e) {
                // noop
                console.error(e);
            }
        });
    }
}

customElements.define('browser-warning', BrowserWarning);
